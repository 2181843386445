import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`International Center for AI and Robotic Autonomy, Inc. (CARA, Inc.) is a non-profit 501(c)(3) research institute, focusing on fundamental research of artificial intelligence and its applications on robotic autonomy. We will strive to attract the global talent with excellency in artificial intelligence (AI) research to work on innovative, curiosity-driven, and pragmatic problems. We believe our efforts will drive a unified theory of AI to solve practical problems in various aspects of industry, foster strong economic growth, and benefit mankind.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      