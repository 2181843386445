import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Paypal = makeShortcode("Paypal");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Outreach`}</h2>
    <p>{`CARA accepts donations using PayPal or Zelle. Our Zelle quick pay email is donate@cara.ai.`}</p>
    <Paypal mdxType="Paypal"></Paypal>
    <p><a parentName="p" {...{
        "href": "mailto:contact@cara.ai"
      }}>{`Contact us for more information`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      